import React from "react";
import "./App.css";

import NavBar from "./components/NavBar";
import WelcomeMsg from "./components/WelcomeMsg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <WelcomeMsg />
    </div>
  );
}

export default App;

import React from "react";
import AppLogo from "../assets/images/firepit-logos_transparent.png";
import "./styles/NavBar.css";

function NavLink(props: { link?: string; children: string }) {
  return (
    <a
      href={props.link ?? "#"}
      target={props.link?.startsWith("http") ? "_blank" : undefined}
      className="p-4 text-gray-300 no-underline transition cursor-pointer hover:text-gray-50"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
}

function NavigationBar() {
  return (
    <div className="navbar">
      <NavLink link="#">Roadmap</NavLink>
      <NavLink link="#">FAQ</NavLink>
      <NavLink link="#">Developer Hub</NavLink>
    </div>
  );
}

export default function NavBar() {
  return (
    <div>
      <div className="relative flex items-center h-full px-5 m-auto sm:container">
        <a href="/" className="absolute flex flex-row items-center">
          <img src={AppLogo} className="logo" />
        </a>
      </div>
    </div>
  );
}
